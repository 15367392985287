import React from "react";
import { useIntl, Link } from "gatsby-plugin-intl";

import logo from "../assets/imgs/logo-bijeli.png";
import { ReactComponent as Mail } from "../assets/imgs/mail.svg";
import { ReactComponent as Phone } from "../assets/imgs/phone.svg";
import Language from "./language";
import { useState } from "react";
import { useEffect } from "react";

const Header = () => {
  const intl = useIntl();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
    const handleResize = () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    };
    window.addEventListener("resize", handleResize, false);

    return () => {
      window.removeEventListener("resize", handleResize, false);
    };
  }, []);

  return (
    <header>
      <div className="primary">
        <div className="content">
          <div className="logo">
            <Link to="/">
              <img alt="logo" src={logo} />
            </Link>
          </div>

          <div className="header-kontakt">
            <div className="phones">
              <a
                className="email"
                href="mailto:irena.sosic2@gmail.com"
              >
                <Mail />
                irena.sosic2@gmail.com
              </a>
              <a className="tel" href="tel:0038598335065">
                <Phone />
                +385(0)98 335 065
              </a>
            </div>

            <div className="phones">
              <a className="tel" href="tel:00385997338185">
                <Phone />
                +385(0)99 733 8185
              </a>
              <a className="tel" href="tel:0038552813974">
                <Phone />
                +385(0)52 813 974
              </a>
            </div>

          </div>
        </div>
      </div>
      <div className={open ? "navigation content open" : "navigation content"}>
        <div className="hamburger-container" onClick={() => setOpen(!open)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100"
            height="78"
            viewBox="0 0 100 78"
          >
            <rect
              id="bar-1"
              width="100"
              height="15"
              rx="5"
              transform="translate(0 0)"
            />
            <rect
              id="bar-2"
              width="80"
              height="15"
              rx="5"
              transform="translate(0 32)"
            />
            <rect
              id="bar-3"
              width="100"
              height="15"
              rx="5"
              transform="translate(0 64)"
            />
          </svg>
        </div>
        <nav>
          <ul>
            <li>
              <Link
                to="/"
                activeClassName="active"
                onClick={() => setOpen(!open)}
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                to="/jedinice"
                activeClassName="active"
                onClick={() => setOpen(!open)}
              >
                {intl.formatMessage({ id: "nav.jedinice" })}
              </Link>
            </li>
            <li>
              <Link
                to="/galerija"
                activeClassName="active"
                onClick={() => setOpen(!open)}
              >
                {intl.formatMessage({ id: "nav.galerija" })}
              </Link>
            </li>
            <li>
              <Link
                to="/lokacija"
                activeClassName="active"
                onClick={() => setOpen(!open)}
              >
                {intl.formatMessage({ id: "nav.lokacija" })}
              </Link>
            </li>
            <li>
              <Link
                to="/kontakt"
                activeClassName="active"
                onClick={() => setOpen(!open)}
              >
                {intl.formatMessage({ id: "nav.kontakt" })}
              </Link>
            </li>

          </ul>
          <Language />
        </nav>
      </div>
    </header>
  );
};

export default Header;
