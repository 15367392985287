import React from "react";
import { useIntl, Link } from "gatsby-plugin-intl";
import CookieConsent from "react-cookie-consent";

import logo from "../assets/imgs/logo-black.svg";
import safe from "../assets/imgs/safe.png";
import { ReactComponent as Mail } from "../assets/imgs/mail.svg";
import { ReactComponent as Phone } from "../assets/imgs/phone.svg";
import { ReactComponent as Marker } from "../assets/imgs/marker.svg";
import { ReactComponent as Arrow } from "../assets/imgs/arrow.svg";
import { ReactComponent as Facebook } from "../assets/imgs/facebook.svg";
import { ReactComponent as Google } from "../assets/imgs/google.svg";
import { ReactComponent as Heart } from "../assets/imgs/heart.svg";

const Footer = () => {
  const intl = useIntl();

  return (
    <>
      <CookieConsent
        buttonText="OK"
        style={{ fontFamily: "Merriweather,sans-serif" }}
        buttonStyle={{ background: "#2196f3" }}
        acceptOnScroll={true}
        acceptOnScrollPercentage={30}
      >
        {intl.formatMessage({ id: "footer.cookie-consent" })}
      </CookieConsent>
      <footer>
        <div className="row content section">
          <div className="col">
            <div className="logo-black">
              <img src={logo} alt="logo" />
            </div>
            <a
              className="email"
              href="mailto:irena.sosic2@gmail.com"
            >
              <Mail />
              irena.sosic2@gmail.com
            </a>
            <a className="tel" href="tel:00385997338185">
              <Phone />
              +385(0)99 733 8185
            </a>
            <a className="tel" href="tel:0038598335065">
              <Phone />
              +385(0)98 335 065
            </a>
            <a className="tel" href="tel:0038552813974">
              <Phone />
              +385(0)52 813 974
            </a>
            <a
              href="https://goo.gl/maps/G5GdwzrdnvpZgn9ZA"
              target="_blank"
              rel="noreferrer"
            >
              <Marker />
              Sebastiana Schiavone 1, 52210, Rovinj, Croatia
            </a>
          </div>
          <div className="col" style={{ alignItems: "center" }}>
            <Link to="/kontakt" className="kontakt-button">
              <h2>{intl.formatMessage({ id: "footer.kontaktirajte-nas" })}</h2>
              <Arrow />
            </Link>
            <div className="external">
              <a href="https://www.google.com/travel/hotels/entity/CgsInv7umubJjfDqARAB?q=irena&gsas=1&rp=CgpYAGAAcgQIAhgAOAGAAgGKAgVpcmVuYagCAQ&ved=0CAAQ5JsGahcKEwjQz7mghYv-AhUAAAAAHQAAAAAQAw&utm_campaign=sharing&utm_medium=link&utm_source=htls&ts=CAESCgoCCAMKAggDEAAaIAoCGgASGhIUCgcI5w8QBBgREgcI5w8QBBgSGAEyAhAAKgkKBToDRVVSGgA">
                <Google />
              </a>
            </div>

          </div>
        </div>
        <div className="extra-links content">
          <a href="/sitemap.xml">
            {intl.formatMessage({ id: "footer.sitemap" })}
          </a>
          <Link to="/pravila-o-privatnosti">
            {intl.formatMessage({ id: "footer.privacy" })}
          </Link>
          <Link to="/obavijest-o-kolacicima">
            {intl.formatMessage({ id: "footer.cookie-notice" })}
          </Link>
        </div>
        <div className="madebybang">
          <a href="https://bang.agency">
            <p>
              Made with <Heart /> by bang.agency
            </p>
          </a>
        </div>
      </footer>
    </>
  );
};

export default Footer;
